import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CareerHeader from "../images/career_header.jpg"
import { withTranslation } from "react-i18next";

const career_header = {
  background: `url(${CareerHeader}) no-repeat center center`,
  backgroundSize: "cover",
}

const CareerPage = ({t, i18n}) => (
  <Layout>
    <SEO title="Kariera" />
    <section className="hero is-large" style={career_header}>
      <div className="hero-body">
        <div className="container"></div>
      </div>
      <div
        className="hero-foot"
        style={{
          paddingTop: "3rem",
          background: "linear-gradient(90deg, white 50%, transparent 50%)",
        }}
      >
        <div className="container">
          <div className="columns">
            <div className="column is-half">
              <div className="content is-offset-1 career-title">
                <p className="title is-1 is-family-secondary has-text-primary">
                  {t("CAREER")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="columns" style={{ paddingBottom: "3rem" }}>
          <div className="column">
            <div className="content">
              <p
                style={{
                  paddingBottom: "3rem",
                  borderBottom: "1px solid #E30C5C",
                }}
              >
                {t("CAREER_ABOUT")}
              </p>
              <p
                style={{
                  paddingTop: "3rem",
                }}
              >
                {t("CV")}
                <span className="has-text-primary">praca@fastigo.pl</span>
              </p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p className="title is-3 is-family-secondary" style={{ marginBottom: "0.5rem" }}>{t("WHO")}</p>
              <ul style={{ marginTop: "0" }}>
                <li>{t("WHO_1")}</li>
                <li>{t("WHO_2")}</li>
                <li>{t("WHO_3")}</li>
                <li>{t("WHO_4")}</li>
                <li>{t("WHO_5")}</li>
                <li>{t("WHO_6")}</li>
              </ul>
              <p className="title is-3 is-family-secondary" style={{ marginBottom: "0.5rem" }}>{t("WHAT")}</p>
              <ul style={{ marginTop: "0" }}>
                <li>{t("WHAT_1")}</li>
                <li>{t("WHAT_2")}</li>
                <li>{t("WHAT_3")}</li>
                <li>{t("WHAT_4")}</li>
                <li>{t("WHAT_5")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default withTranslation("Career")(CareerPage)
