import React from "react"
import { Link } from "gatsby"

import Logo from '../images/Fastigo_Logo.svg'
import { withTranslation } from "react-i18next";

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      value: ''
    };

    this.hamburgerMenuHandler = this.hamburgerMenuHandler.bind(this); 
  }

  componentDidMount() {
    this.setState({value: localStorage.getItem("i18nextLng")})
  }

  hamburgerMenuHandler() {
    this.setState({
      open: !this.state.open
    });
  }

  handleChange = event => {
    let newlang = event;
    this.setState(prevState => ({ value: newlang }));
    this.props.i18n.changeLanguage(newlang);
  };

  render() {
    const { t } = this.props;
    const { open } = this.state;
    
    return (
      <nav className="navbar is-fixed-top is-spaced" role="navigation" aria-label="main navigation">
        <div className="container">
        
          <div className="navbar-brand">
            <Link to="/">
              <img src={Logo} className="navbar-logo" alt="fastigo-logo"/>
            </Link>
            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarHamburger"
              onClick={this.hamburgerMenuHandler}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
  
          <div id="navbarHamburger" className={open ? "navbar-menu is-active" : "navbar-menu"}>
            <div className="navbar-end">
              <Link to="/#o-nas" className="navbar-item ">{t("TRANSPORT")}</Link>
              <Link to="/polityka-jakosci" className="navbar-item">{t("QUALITY_POLICY")}</Link>
              <Link to="/zlecenia-spedycyjne" className="navbar-item">{t("SHIPPING_ORDER")}</Link>
              <Link to="/kariera" className="navbar-item">{t("CAREER")}</Link>
              <a href="http://recykling.fastigo.pl/" target="_blank" className="navbar-item">{t("RECYCLING")}</a>
              <Link to="/kontakt" className="navbar-item has-text-weight-bold has-text-primary">{t("CONTACT")}</Link>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">{this.state.value === 'pl-PL' ? 'PL' : this.state.value.toUpperCase()}</a>
                <div className="navbar-dropdown is-boxed">
                  <a className="navbar-item" name="pl" onClick={e => this.handleChange(e.target.name)}>PL</a>
                  <a className="navbar-item" name="de" onClick={e => this.handleChange(e.target.name)}>DE</a>
                  <a className="navbar-item" name="en" onClick={e => this.handleChange(e.target.name)}>EN</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default withTranslation("Navbar")(Navbar)
