import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"

import Logo from "../images/logo.png"
import { withTranslation } from "react-i18next"

const Footer = ({ t, i18n }) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="columns">
          <div className="column">
            <img src={Logo} alt="fastigo-logo" />
          </div>
          <div className="column is-one-third">
            <div className="content">
              <p className="title is-5 is-family-secondary">
                Fastigo Logistics
              </p>
              <p>ul. Jabłoniowa 52, 40-111 Katowice</p>
              <p>e-mail: biuro@fastigo.pl, office@fastigo.pl</p>
              <p>tel./fax: 0048 32 353 5050</p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p className="title is-5 is-family-secondary">{t("SERVICES")}</p>
              <p>
                <Link className="has-text-black" to="/#spedycja-drogowa">
                  {t("ROAD_FORWARDING")}
                </Link>
              </p>
              <p>
                <Link className="has-text-black" to="/#spedycja-morska">
                  {t("SEA_FORWARDING")}
                </Link>
              </p>
              <p>
                <Link className="has-text-black" to="/#express">
                  {t("STORAGE")}
                </Link>
              </p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p className="title is-5 is-family-secondary">{t("MORE")}</p>
              <p>
                <Link className="has-text-black" to="/polityka-jakosci">
                  {t("QUALITY_POLICY")}
                </Link>
              </p>
              <p>
                <Link className="has-text-black" to="/kariera">
                  {t("CAREER")}
                </Link>
              </p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p className="title is-5 is-family-secondary">
                {t("CONTACT_US")}
              </p>
              <p>
                <Link className="has-text-black" to="/zlecenia-spedycyjne">
                  {t("FORWARDING_ORDER")}
                </Link>
              </p>
              <p>
                <Link className="has-text-black" to="/kontakt">
                  {t("CONTACT")}
                </Link>
              </p>
            </div>
          </div>
        </div>
        <nav className="tabs is-right">
          <div className="container">
            <div className="socialmedia">
              <span></span>
              <div className="socialmedia-item">
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size="2x"
                  color="#E30C5C"
                />
              </div>
              <div className="socialmedia-item">
                <FontAwesomeIcon icon={faTwitter} size="2x" color="#E30C5C" />
              </div>
              <div className="socialmedia-item">
                <a
                  className="is-paddingless"
                  href="https://www.facebook.com/fastigologistics"
                  style={{ borderBottom: "none" }}
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    size="2x"
                    color="#E30C5C"
                  />
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </footer>
  )
}

export default withTranslation("Footer")(Footer)
